.archiveContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.archiveTitle {
    font-size: 24px;
    margin-bottom: 20px;
}

.label, .centerLabel {
    font-weight: bold;
    text-align: left;
    width: 100%; 
    margin-bottom: 5px;
}

.centerLabel {
    margin-bottom: 10px;
    margin-top: 15px;
}

.archiveTable {
    width: 100%; 
    border-collapse: collapse;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    table-layout: fixed; 
}

.archiveTable thead {
    background-color: #E18E18;
    color: white;
}

.archiveTable th,
.archiveTable td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    word-break: break-word;
}

.archiveTable tr:nth-child(even) {
    background-color: #f2f2f2;
}

.archiveTable tbody tr:hover {
    background-color: #ddd;
}

.archiveTable td {
    vertical-align: top; 
}

.noData {
    text-align: center;
    color: #666;
    font-size: 16px;
}

.searchFilter, .clientFilter, .dateFilter {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.searchFilter input,
.clientFilter select {
    width: 100%;
    padding: 5px;
    border: 2px solid #002559;
    border-radius: 4px;
    margin-bottom: 10px;
}

.dateFilter {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-direction: row;
}

.dateFilter input {
    padding: 5px;
    border: 2px solid #002559;
    border-radius: 4px;
}

@media (max-width: 480px) {
    .archiveTable th, .archiveTable td {
        padding: 5px;
        font-size: 12px;
    }

    .archiveTitle {
        font-size: 20px;
    }

    .noData {
        font-size: 14px;
    }

    .searchFilter, .clientFilter, .dateFilter, .exportButton {
        width: 100%;
    }

    .searchFilter input, .clientFilter select, .dateFilter input, .exportButton {
        padding: 5px;
        font-size: 14px;
        width: 100%;
    }

    .dateFilter {
        flex-direction: column;
        align-items: center;
    }

    .dateFilter input:last-child {
        margin-bottom: 0;
    }
}
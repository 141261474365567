.listBoxContainer {
  color: #4D4C4C;
  height: calc(100vh - 325px); 
  overflow-y: auto; 
  margin-bottom: 20px; 
  width: 100%;
  border-radius: 8px; 
  -webkit-overflow-scrolling: touch; 
}

.listBox {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
}

.listItem {
  padding: 10px; 
  border-bottom: 1px solid #ccc; 
  display: flex; 
  justify-content: space-between; 
  align-items: center; 
  cursor: pointer;
}

.deleteButton {
  background-color: transparent; 
  color: #FF0065;
  border: none; 
  padding: 0; 
  font-size: 1rem; 
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px; 
}

.deleteButton:hover {
  color: #FF0000; 
}
.barcodeGeneratorContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    min-height: 80vh;
    background: #DEDEDE;
}

.generatorForm {
    width: 90%;
    max-width: 500px; /* Limit the width on larger screens */
    background: #FFFFFF;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.formGroup {
    margin-bottom: 20px;
}

.formLabel {
    display: block;
    margin-bottom: 5px;
    color: #4D4C4C;
    font-weight: bold;
}

.formControl {
    width: 100%;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
}

.formSelect {
    width: 100%;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 4px;
    background: white;
    font-size: 1em;
}

.submitBtn {
    width: 100%;
    background-color: #FF0065;
    color: #FFFFFF;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
}

.barcodeContainer {
    width: 90%;
    max-width: 500px; /* Limit the width on larger screens */
    margin-top: 20px;
    padding: 20px;
    background: #E0E0E0;
    border-radius: 10px;
    text-align: center;
}

.error {
    color: #FF0065;
    margin: 10px 0;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .barcodeGeneratorContainer {
        padding: 10px;
    }

    .generatorForm {
        width: calc(100% - 20px);
    }
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}
  
.modal {
    background: #FFFFFF;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 90%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.modalHeader, .modalContent {
    margin-bottom: 1rem;
}

.modalContent {
    max-height: 250px; 
    overflow-y: auto; 
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
}

.closeButton {
    background-color: #E18E18; 
    color: #FFFFFF; 
    border: none; 
    padding: 10px; 
    border-radius: 4px; 
    cursor: pointer; 
    font-weight: bold; 
    width: auto; 
    margin-top: 0; 
}

.editButton {
    background-color: #FFFFFF; 
    color: #E18E18; 
    border: 2px solid #E18E18; 
    padding: 8px 12px; 
    border-radius: 4px; 
    cursor: pointer; 
    font-weight: bold; 
    text-transform: uppercase; 
    width: auto; 
    margin-top: 0; 
}

h2 {
    color: #4D4C4C;
    margin-bottom: 1rem;
}

ul {
    list-style: none;
    padding: 0;
    overflow-y: auto;
    border-top: 1px solid #ccc;
    padding-top: 10px;
    margin-bottom: 1rem;
}

li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    color: #4D4C4C;
}

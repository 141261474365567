.loginFormContainer {
    flex-grow: 1; 
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    padding: 20px;
    width: 100%;
    max-width: 400px;
    margin: auto;
}

.loginForm {
    width: 100%;
    text-align: center;
}

.loginFormField {
    position: relative;
    margin-bottom: 30px; /* Increased bottom margin for more space */
}

.loginForm h2 {
    margin-bottom: 30px; /* Increased bottom margin for more space */
}

.loginFormField label {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease;
    pointer-events: none;
}

.loginFormField.focused label, .loginFormField input:not(:placeholder-shown) + label {
    top: -10px;
    left: 10px;
    font-size: 0.75em;
    color: #002559;
}

.loginFormField input[type="text"], .loginFormField input[type="password"] {
    width: 100%;
    padding: 12px;
    font-size: 1em;
    border: 2px solid #002559;
    border-radius: 4px;
    box-sizing: border-box;
}

.loginFormButton {
    width: 100%;
    background-color: #002559;
    color: #E18E18;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

.loginForm p {
    margin-top: 15px;
}
  
.loginForm a {
    color: #E18E18;
    text-decoration: none;
}

@media (max-width: 480px) {
    .loginFormField label {
        font-size: 0.7em;
    }
}
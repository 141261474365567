.archiveContainer {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
}

.archiveTitle {
    color: #333;
    margin-bottom: 20px;
}

.filter {
    margin-bottom: 20px;
}

.label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.input,
.filter select {
    width: 100%;
    padding: 10px;
    border: 1px solid #002559;
    border-radius: 4px;
    margin-bottom: 10px;
}

.dateFilters {
    display: flex;
    justify-content: space-between;
}

.archiveTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.archiveTable th,
.archiveTable td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
    text-align: left;
}

.archiveTable th {
    background-color: #E18E18;
    color: white;
}

.archiveTable td {
    vertical-align: top; 
}

.noData {
    text-align: center;
    margin-top: 20px;
}

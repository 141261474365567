.landingPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 94vh;
    background-color: #DEDEDE;
}

.welcomeMessage {
    font-size: 1.5em;
    color: #002559;
    margin-bottom: 75px; /* Adjust as needed */
    white-space: nowrap; /* Keep text in a single line */
    overflow: hidden; /* Hide overflow */
    border-right: .15em solid #002559; /* Cursor styling */
    animation: typing 2s steps(12), blink 1s infinite step-end alternate;
    text-align: center; /* Center text alignment */
    width: 100%; /* Full width to center the text */
}

@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

@keyframes blink {
    from { border-color: transparent }
    to { border-color: #002559 }
}

.landingPageButton {
    background-color: #FFFFFF;
    color: #002559;
    border: 2px solid #002559;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    width: 200px;
    text-align: center;
}

@media (max-width: 480px) {
    .landingPageButton {
      font-size: 0.8em;
      padding: 5px 10px;
      width: 150px;
    }
}
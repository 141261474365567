.userManagement {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
}

.userManagement h2 {
  color: #333;
  margin-bottom: 20px;
}

.userManagementHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.userManagementForm {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

.userManagementForm input, .userManagementForm button, .userManagementForm select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  flex-grow: 1;
  min-width: 100px;
}

.userManagementForm button {
  background-color: #E18E18;
  color: white;
  border: none;
}

.userManagementForm button:hover {
  background-color: #D17D17;
}

.editButton {
  background-color: #E18E18;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
}

.editButton:hover {
  background-color: #D17D17;
}

.userTable {
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
}

.userTable th {
  padding: 8px;
  border-bottom: 2px solid #ddd;
  background-color: #fff;
}

.userTable td {
  padding: 8px;
  border-bottom: 1px solid #eee;
}

.userTable th, .userTable td {
  text-align: left;
}

.userList {
  list-style-type: none;
  padding-left: 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { opacity: 0; width: 0; height: 0; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #E18E18;
}

input:focus + .slider {
  box-shadow: 0 0 1px #E18E18;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.deleteButton {
  background: none;
  border: none;
  cursor: pointer;
  display: flex; 
  align-items: center;
  justify-content: center;
  color: #dc3545
}

.invisibleDeleteButton {
  display: flex;
  width: 24px; 
  height: 24px;
  visibility: hidden; 
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalBody {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 300px; /* Adjust width as needed */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalBody h2 {
  color: #333;
  margin-bottom: 20px;
}

.modalBody input[type="password"] {
  width: 100%; /* Adjust width as needed, or use max-width for better responsiveness */
  padding: 10px;
  margin-bottom: 20px; /* Space between input and buttons */
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modalActions {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.modalActions button {
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.passwordResetForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.passwordInput {
  width: 90%; /* Adjust based on your preference */
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.updateButton, .cancelButton {
  padding: 10px 20px;
  margin: 5px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  width: 100px; /* Ensures buttons are the same width */
}

.updateButton {
  background-color: #4CAF50; /* A green color */
  color: white;
}

.cancelButton {
  background-color: #f44336; /* A red color */
  color: white;
}

.updateButton:hover, .cancelButton:hover {
  opacity: 0.8;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px; /* Adjust the spacing between buttons */
}

.deleteButton, .changePasswordButton {
  padding: 8px 16px; /* Ensure padding is consistent */
  font-size: 14px; /* Adjust based on your preference */
  border-radius: 4px;
  cursor: pointer;
}

.deleteButton {
  background: none;
  border: none;
  color: #dc3545;
}

.changePasswordButton {
  background-color: #4CAF50; /* A green color */
  color: white;
  border: none;
}

.changePasswordButton:hover {
  background-color: #45a049; /* Darker shade of green for hover effect */
}

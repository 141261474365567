.newFormContainer {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 400px;
  margin: auto;
  background: #FFFFFF;
  padding: 20px;
  border-radius: 8px;
  overflow: hidden; 
}

.newForm {
  display: flex;
  flex-direction: column;
  min-height: 0; 
}

.inputGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.referenceInput,
.skuInput,
.locationInput {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 12px;
  margin-bottom: 0; 
  background-color: #FFFFFF;
}

.addSkuButton {
  width: 100%;
  background-color: #FFFFFF;
  color: #FF0065;
  border: 2px solid #FF0065;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  align-self: center; 
  margin-top: 0.5rem; 
}

.skuList {
  color: #4D4C4C;
  overflow-y: auto;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.skuItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.skuText {
  margin: 0;
}

.skuItem .removeSkuButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem; 
  display: inline-flex;
  align-items: center;
  color: #FF0065;
  margin-left: 10px; 
}

.skuItem .removeSkuButton:hover {
  color: #FF0000; 
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: flex-start; 
  align-items: center;
  margin-right: 10px; 
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  
}

.backButton {
  font-size: 0.9em;
  box-sizing: border-box;
  width: calc(33.33% - 5px);
  background-color: #FFFFFF;
  color: #002559;
  border: 2px solid #002559;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 10px;
}

.submitButton {
  width: calc(66.66% - 5px);
  background-color: #E18E18;
  color: #FFFFFF;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}
.returnPage {
    flex-grow: 1; 
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    align-items: center;
    padding: 20px 0;
    background-color: #DEDEDE;
    height: 94vh;
}

.listBox {
    width: 90%;
    max-height: calc(100vh - 160px); 
    overflow-y: auto;
    background: #FFFFFF;
    color: #4D4C4C;
    padding: 20px;
    border-radius: 10px;
}

.listHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}
  
.searchContainer {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: auto; 
}
  
.searchInput {
    display: block; 
    padding: 10px;
    border: 2px solid #002559;
    border-radius: 4px;
    width: 0;
    opacity: 0;
    transition: width 0.5s ease-in-out, opacity 0.5s ease-in-out; 
    right: 25px; 
    top: 0;
    margin-bottom: 10px;
}
  
.searchInputVisible {
    width: 200px; 
    opacity: 1; 
    right: 0; 
}
  
.searchIcon {
    position: absolute;
    right: 0; 
    color: #002559;
    cursor: pointer;
    z-index: 10; 
}

.searchIconHidden {
    display: none;
}  

.newReturnBtn {
    background-color: #FFFFFF;
    color: #002559;
    border: 2px solid #002559;
    border-radius: 4px; 
    padding: 10px 20px;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px; 
    margin-bottom: 10px;
}

.buttonGroup {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    
}
  
.backButton {
    font-size: 0.9em;
    box-sizing: border-box;
    width: calc(33.33% - 5px);
    background-color: #FFFFFF;
    color: #002559;
    border: 2px solid #002559;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 10px;
}
  
.exportButton {
    width: calc(66.66% - 5px);
    background-color: #E18E18;
    color: #FFFFFF;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

@media (max-width: 480px) {
    .newReturnBtn {
        font-size: 0.8em; /* Smaller font size */
        padding: 5px 5px; /* Reduced padding */
        gap: 5px; /* Reduced gap between icon and text */
    }

    .newReturnBtn > IoCreateOutline {
        font-size: 1em; /* Adjust icon size */
    }

    .searchIcon {
        margin-bottom: 10px;
    }

    .searchInputVisible {
        width: 100%; /* Adjust width for smaller screens */
        padding: 5px; /* Adjust padding */
        font-size: 0.8em; /* Smaller font size */
    }
}
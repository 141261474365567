.userManagement {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
}

.userManagement h2 {
  color: #333;
  margin-bottom: 20px;
}

.userManagementHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.columnTitles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-weight: bold;
  color: #333;
  border-bottom: 1px solid #eee;
}

.userManagementForm {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

.userManagementForm input, .userManagementForm select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-grow: 1;
  min-width: 100px;
}

.userManagementForm button {
  padding: 10px 15px;
  background-color: #E18E18;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.userManagementForm button:hover {
  background-color: #E18E18;
}

.editButton {
  padding: 10px 15px;
  background-color: #E18E18;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.editButton:hover {
  background-color: #E18E18;
}

.userList {
  list-style: none;
  padding: 0;
}

.userList li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.userControls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.deleteButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteButton:hover {
  background-color: transparent; 
}

.deleteButton svg {
  color: #dc3545; 
  width: 24px; 
  height: 24px; 
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #E18E18;
}

input:focus + .slider {
  box-shadow: 0 0 1px #E18E18;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.returnsManagement {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    color: #333;
}

.sgdExport {
    padding: 20px;
    margin: 20px;
    background-color: #fff;
    border-radius: 10px;
    color: #333; 
}

.returnsManagement h2 {
    margin-bottom: 20px;
}

.filterContainer {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;
}

.userDropdown {
    padding: 10px;
    border: 1px solid #002559;
    border-radius: 4px;
    background-color: white;
}

.dateInput {
    padding: 10px;
    border: 1px solid #002559;
    border-radius: 4px;
}

.exportButton {
    padding: 10px 15px;
    background-color: #E18E18;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.exportButton:hover {
    background-color: #E18E18;
}


.adminDashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    background-color: #DEDEDE;
    height: 100%;
}

.adminNav {
    width: 90%;
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

.navButton {
    padding: 15px 30px;
    border: none;
    background: none;
    cursor: pointer;
    font-weight: bold;
    color: #4D4C4C;
    transition: background-color 0.3s ease;
}

.navButton:hover {
    background-color: #E0E0E0;
}

.adminContent {
    width: 90%;
    background: #FFFFFF;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.userManagement, .returnsManagement {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userList, .returnList {
    width: 100%;
    max-height: 500px; /* Adjust as needed */
    overflow-y: auto;
    background: #E0E0E0;
    color: #4D4C4C;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.userListItem, .returnListItem {
    padding: 10px;
    border-bottom: 1px solid #CCCCCC;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lastItem {
    border-bottom: none;
}

.actionButton {
    background-color: #FF0065;
    color: #FFFFFF;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    margin-left: 10px;
}

.actionButtonSecondary {
    background-color: #FFFFFF;
    color: #FF0065;
    border: 2px solid #FF0065;
}

.exportButton {
    background-color: #FF0065;
    color: #FFFFFF;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
}

.header {
    background-color: #002559;
    color: #E18E18;
    width: 100%;
    padding: 10px 0;
    text-align: center;
}

.title {
    margin: 0;
    font-size: 2em; 
}

/*
.header {
    background-color: #002559;
    color: #E18E18;
    width: 100%;
    padding: 10px 0;
    text-align: center;
}

.logo {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto; 
    height: 3em;
    width: auto;
}
*/